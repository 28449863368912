<template>
  <div class="main">
    <router-view class="router-view"></router-view>
  </div>
</template>

<script>
export default {
  name: 'yaoshiEntry',
  created() {
    document.title = '处方审核';
  },
}
</script>

<style lang="less" scoped>
.main {
  background: #F4F8FB;
  min-height: 100vh;

  .router-view {
    width: 100%;
    padding: 12px;
    overflow-x: hidden;
    box-sizing: border-box;
  }
}
</style>
